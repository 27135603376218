import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { rounded } from 'src/styles/rounded'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      max-width: 299px;
    } 

    @media ${device.desktopLG} {
      max-width: 532px;
    } 
  }
`

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  background-color: #FDF8EE;
  border-radius: ${rounded['6']};
  text-align: center;
`

import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  button, a {
    display: flex;
    justify-content: center;
    height: 48px;

    @media ${device.tablet} {
      max-width: 299px;
    }

    @media ${device.desktopLG} {
      max-width: 496px;
    }

    @media ${device.desktopXL} {
      max-width: 577px;
    }
  }
`

import React from 'react'

// Components
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

// Style
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
}

const NaoCobramosMensalidades = ({ openModal }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row align-items-center pr-xl-1'>
          <div className='col-12 col-lg-5 mb-3'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-40 lh-xl-50 text-grayscale--500'>
              <span className='d-lg-block'>Por que não</span> cobramos mensalidade?
            </h2>
            <p className='fs-18 lh-22 text-grayscale--400'>
              Nascemos para fazer diferente. Participamos da vida de milhões de pessoas, reunindo em uma mesma plataforma soluções que fazem a diferença no seu dia a dia. 
            </p>
            <p className='fs-18 lh-22 text-grayscale--400'>
              E por isso, criamos a primeira conta 100% digital e gratuita do Brasil.
            </p>
            <p className='fs-18 lh-22 text-grayscale--400'>
              Como isso é possível? A gente explica.
            </p>
            {width > WIDTH_MD && <div className='d-flex justify-content-center'>
              <button
                className='btn text-white btn-orange--extra text-none mt-2 mt-md-4'
                title='Abrir Conta Kids gratuita'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Cartão de débito para maior segurança',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </button>
            </div>}
          </div>
          <div className='col-12 col-lg-6 offset-lg-1 mt-md-2'>
            <div className='row'>
              <S.FaresCard className='col-12 col-lg-12 px-2 mb-3'>
                <div className='bg-white d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='smartphones' />
                  </div>
                  <div className='pt-md-0'>
                    <h3 className='fs-16 text-grayscale--500 mb-2'>Somos digitais</h3>
                    <p className='fs-14 fs-xl-16 lh-22 text-grayscale--400 mb-0'>
                      Como você resolve tudo pela internet, não precisamos de agências. Isso aumenta a nossa eficiência e gera economia.
                    </p>
                  </div>
                </div>
              </S.FaresCard>
              <S.FaresCard className='col-12 col-lg-12 px-2 mb-3'>
                <div className='bg-white d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='services' />
                  </div>
                  <div className='pt-md-0'>
                    <h3 className='fs-16 text-grayscale--500 mb-2'>Temos processos automatizados</h3>
                    <p className='fs-14 fs-xl-16 lh-22 text-grayscale--400 mb-0'>
                      Também economizamos com o custo operacional. Criamos processos inteligentes, que reduzem esforço e burocracia.
                    </p>
                  </div>
                </div>
              </S.FaresCard>
              <S.FaresCard className='col-12 col-lg-12 px-2 mb-3'>
                <div className='bg-white d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='light-on' />
                  </div>
                  <div className='pt-md-0'>
                    <h3 className='fs-16 text-grayscale--500 mb-2'>Somos sustentáveis</h3>
                    <p className='fs-14 fs-xl-16 lh-22 text-grayscale--400 mb-0'>
                      Como não temos agência e somos digitais, economizamos papel, energia e outros recursos.
                    </p>
                  </div>
                </div>
              </S.FaresCard>
              <S.FaresCard className='col-12 col-lg-12 px-2'>
                <div className='bg-white d-flex align-items-center bg-gray rounded-2 mb-2 px-2 py-3'>
                  <div className='px-3 pl-md-2'>
                    <OrangeIcon size='MD' color='#FF7A00' icon='dollar' />
                  </div>
                  <div className='pt-md-0'>
                    <h3 className='fs-16 text-grayscale--500 mb-2'>Ganhamos em escala</h3>
                    <p className='fs-14 fs-xl-16 lh-22 text-grayscale--400 mb-0'>
                      Você utiliza sua Conta Digital e outros serviços, fica satisfeito com a gente e nos indica para os seus amigos.
                    </p>
                  </div>
                </div>
              </S.FaresCard>
            </div>
            {width === WIDTH_MD && <div className='d-flex justify-content-center'>
              <button
                className='btn text-white btn-orange--extra text-none mt-2 mt-md-4'
                title='Abrir Conta Kids gratuita'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Cartão de débito para maior segurança',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </button>
            </div>}
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default NaoCobramosMensalidades

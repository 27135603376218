import React from 'react'

import Icon from 'src/components/UI/MarkdownIcon'
import PorqueAbrirContaKidsJSON from '../../assets/data/PorqueAbrirContaKids.json'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'
import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

type PorqueAbrirContaKidsProps = {
  icon: string;
  title: string;
  description: string;
}

const PorqueAbrirContaKids = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='py-5 bg-grayscale--100'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-12 mt-3'>
            <h2 className='fs-24 fs-md-30 fs-xl-32 text-grayscale--500 mb-4 text-md-center'>
              Por que abrir uma Conta Kids?
            </h2>
          </div>
        </div>
        <div className='row'>
          {
            PorqueAbrirContaKidsJSON.map((item: PorqueAbrirContaKidsProps) => (
              <div key={item.title} className='col-12 col-md-6 mb-3'>
                <S.Card className='bg-white rounded-2 d-flex d-md-block d-lg-flex align-items-center py-3'>
                  <div className='px-3 pr-lg-2 pl-lg-4 mb-md-3'><Icon width='33' height='33' icon={item.icon} directory='v2' /></div>
                  <div className='ml-md-3 pr-3'>
                    <h3 className='fs-14 fs-md-16 lh-16 lh-md-20 text-grayscale--500 fw-700 mb-2'>{item.title}</h3>
                    <p className='fs-14 lh-16 text-grayscale--400 mb-0'>{item.description}</p>
                  </div>
                </S.Card>
              </div>
            ))
          }
        </div>
        <div className='col-12 mt-lg-2 d-flex justify-content-center'>
          {width > WIDTH_MD ? (
            <button
              className='btn text-white btn-orange--extra text-none'
              title='Abrir Conta Kids gratuita'
              onClick={() => {
                openModal()
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Por que abrir uma Conta Kids?',
                  element_action: 'click button',
                  element_name: 'Abrir Conta Kids gratuita',
                })
              }}
            >
              Abrir Conta Kids gratuita
            </button>
            ) : (
              <a
                href={oneLink}
                title='Abrir Conta Kids gratuita'
                className='btn text-white btn-orange--extra text-none mt-2'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_02',
                    section_name: 'Por que abrir uma Conta Kids?',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                    redirect_url: oneLink,
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </a>
            )}
        </div>
      </div>
    </S.Section>
  )
}

export default PorqueAbrirContaKids

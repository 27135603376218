import React from 'react'
import Faq from 'src/components/Faq/index'

// Style
import * as S from './style'

import pageContext from '../../pageContext.json'

const PerguntasFrequentes = () => {
  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='text-grayscale--400'>Perguntas frequentes</h3>
          </div>
          <Faq
            id='conta-kids'
            columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
            className='summary-content px-0'
            answerData={pageContext.structuredData.faq}
            questionColor='text-gray--800'
            answersColor='text-grayscale--500'
            borderColor='#fff'
          />
        </div>
      </div>
    </S.Section>
  )
}

export default PerguntasFrequentes

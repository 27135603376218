import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  button {
    height: 48px;

    @media ${device.tablet} {
      max-width: 457px;
    }
  }
`

export const FaresCard = styled.div`
  .bg-white {
    @media ${device.desktopLG} {
      min-height: 146px;
    }
    @media ${device.desktopXL} {
      min-height: 140px;
    }
  }
`

import styled from 'styled-components'
import { rounded } from 'src/styles/rounded'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  button, .link {
    display: flex;
    justify-content: center;
    height: 48px;

    @media ${device.tablet} {
      max-width: 698px;
    }
    
    @media ${device.desktopLG} {
      max-width: 532px;
    }

    @media ${device.desktopXL} {
      max-width: 577px;
    }
}
`

export const Number = styled.div`
  width: 24px;
  height: 24px;
  background-color: #FDF8EE;
  color: #EA7100;
  border-radius: ${rounded['6']};
  text-align: center;
  font-size: 14px;
  font-weight: 700;
`

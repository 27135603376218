import React from 'react'

import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

const Hero = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='pb-5 d-lg-flex position-relative'>
      <div className='container'>
        <div className='row align-items-md-center'>
          {width >= WIDTH_MD && (
            <div className='col-12 col-md-6 col-lg-5 offset-xl-1 order-md-last d-flex justify-content-end'>
              <ImageWebp
                pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/kids-hero/image.webp'
                altDescription='Mulher, criança e homem sorrindo em frente a um computador'
                arrayNumbers={[ 300, 300, 340, 450 ]}
                arrayNumbersHeight={[ 300, 300, 340, 450 ]}
              />
            </div>
          )}
          <div className='col-12 col-md-6 col-lg-7 col-xl-6'>
            <div className='mt-3 mt-lg-2'>
              <h1 className='fs-24 fs-md-30 fs-xl-40 lh-28 lh-md-36 lh-xl-48 mb-0 mb-md-2 fw-600'>Conta Kids</h1>
              <h2 className='fs-20 fs-md-32 lh-25 lh-md-38 font-sora text-grayscale--500 mt-2 w-600'>
                A conta digital para menores de idade
              </h2>
              <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-grayscale--400 mt-2'>
                Completa e gratuita pra você começar a planejar e a investir no futuro do seu filho, sem preocupação.
              </p>
              {width > WIDTH_MD ? (
                <button
                  className='btn text-white btn-orange--extra text-none'
                  title='Abrir Conta Kids gratuita'
                  onClick={() => {
                    openModal()
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: 'Conta Kids',
                      element_action: 'click button',
                      element_name: 'Abrir Conta Kids gratuita',
                    })
                  }}
                >
                  Abrir Conta Kids gratuita
                </button>
                ) : (
                  <a
                    href={oneLink}
                    title='Assinar agora'
                    className='btn text-white btn-orange--extra text-none mt-2'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_01',
                        section_name: 'Conta Kids',
                        element_action: 'click button',
                        element_name: 'Assinar agora',
                        redirect_url: oneLink,
                      })
                    }}
                  >
                    Abrir Conta Kids gratuita
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero

import React from 'react'

// Components
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

// Style
import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

const ComoAbrirContaKids = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-5 d-flex justify-content-center justify-content-lg-start'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/kids-dobra3/image.webp'
              altDescription='Mãe e filha - Conta Digital Kids Inter'
              arrayNumbers={[ 312, 320, 340, 450 ]}
              arrayNumbersHeight={[ 282, 289, 307, 406 ]}
            />
          </div>
          <div className='col-12 col-lg-7 col-xl-6 offset-xl-1 text-left mt-3'>
            <h2 className='fs-24 fs-md-30 fs-xl-32 lh-28 lh-md-36 lh-xl-38 text-grayscale--500 text-md-center text-lg-left mt-3 mt-md-1 mt-lg-0'>
              Como abrir uma Conta Kids
            </h2>
            <p className='fs-14 fs-md-16 fs-xl-18 lh-16 lh-md-19 lh-xl-22 text-grayscale--400 text-md-center text-lg-left mb-3 mb-md-4'>
              Veja como é fácil abrir a Conta Kids! É importante dizer que o menor de idade precisa ter CPF e RG, combinado?
            </p>
            <div className='d-flex'>
              <S.Number>1</S.Number>
              <div className='ml-3'>
                <p className='fs-16 lh-20 text-grayscale--500 mb-2 fw-700'>Baixe o app</p>
                <p className='fs-16 lh-16 text-grayscale--400'>Vá até a sua loja de aplicativos e baixe o app Inter.</p>
              </div>
            </div>
            <div className='d-flex'>
              <S.Number>2</S.Number>
              <div className='ml-3'>
                <p className='fs-16 lh-20 text-grayscale--500 mb-2 fw-700'>Insira os dados</p>
                <p className='fs-16 lh-16 text-grayscale--400'>Preencha os dados da criança ou adolescente e também os do responsável pela conta.</p>
              </div>
            </div>
            <div className='d-flex'>
              <S.Number>3</S.Number>
              <div className='ml-3'>
                <p className='fs-16 lh-20 text-grayscale--500 mb-2 fw-700'>Envie selfie e documentos</p>
                <p className='fs-16 lh-16 text-grayscale--400'>Registre uma selfie e os documentos do menor de idade e do responsável.</p>
              </div>
            </div>
            <p className='text-grayscale--400 fs-14 fs-md-16 lh-16 lh-md-22 mb-0 mt-md-3'>
              Quer saber mais sobre como abrir a Conta Kids?
              <a
                className='fw-600 ml-2 mt-2'
                href='https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/'
                target='_blank'
                rel='noreferrer'
                title='Acessar o nosso blog'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    element_action: 'click button',
                    element_name: 'Veja no nosso blog',
                    section_name: 'Como abrir uma Conta Kids',
                    redirect_url: 'https://blog.inter.co/passo-a-passo-para-abrir-uma-conta-digital-para-menores-de-18-anos/',
                  })
                }}
              >
                Veja no nosso blog.
              </a>
            </p>
            {width >= WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-md-3'
                title='Abrir Conta Kids gratuita'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Como abrir uma Conta Kids',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </button>
            ) : (
              <a
                href={oneLink}
                title='Abrir Conta Kids gratuita'
                className='btn text-white btn-orange--extra text-none link mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_03',
                    section_name: 'Como abrir uma Conta Kids',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                    redirect_url: oneLink,
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </a>
            )}
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default ComoAbrirContaKids

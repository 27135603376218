import React from 'react'

// Components
import ImageWebp from 'src/components/ImageWebp'
import useWidth from 'src/hooks/window/useWidth'
import { WIDTH_MD } from 'src/styles/breakpoints'

import Card from '../../assets/images/card.png'
import User from '../../assets/images/user.png'
import Block from '../../assets/images/block.png'

// Style
import * as S from './style'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

interface IProps {
  openModal: () => void;
  oneLink: string;
}

const CartaoDeDebito = ({ openModal, oneLink }: IProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  return (
    <S.Section className='py-5'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-5 d-flex justify-content-center justify-content-lg-start'>
            <ImageWebp
              pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/kids-dobra5/image.webp'
              altDescription='Cartão de débito Inter'
              arrayNumbers={[ 312, 340, 340, 450 ]}
              arrayNumbersHeight={[ 312, 340, 340, 450 ]}
            />
          </div>
          <div className='col-12 col-md-6 col-lg-7 col-xl-6 offset-xl-1 text-left mt-3'>
            <h2 className='fs-24 fs-md-30 fs-xl-32 lh-28 lh-md-36 lh-xl-38 text-grayscale--500 mb-4 mt-3 mt-md-1 mt-lg-0 fw-700'>
              Cartão de débito para maior segurança
            </h2>
            <div className='d-flex align-items-center mb-3'>
              <div><img src={Card} /></div>
              <div className='ml-3'>
                <p className='fs-14 fs-md-16 lh-16 text-grayscale--400 fw-700 mb-0'>Cartão de débito Mastercard</p>
              </div>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div><img src={User} /></div>
              <div className='ml-3'>
                <p className='fs-14 fs-md-16 lh-16 text-grayscale--400 fw-700 mb-0'>Nome da criança no cartão</p>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <div><img src={Block} /></div>
              <div className='ml-3'>
                <p className='fs-14 fs-md-16 lh-16 text-grayscale--400 fw-700 mb-0'>Bloqueio pelo app em caso de perda, furto ou roubo</p>
              </div>
            </div>
            {width > WIDTH_MD ? (
              <button
                className='btn text-white btn-orange--extra text-none mt-md-4'
                title='Abrir Conta Kids gratuita'
                onClick={() => {
                  openModal()
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Cartão de débito para maior segurança',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </button>
            ) : (
              <a
                href={oneLink}
                title='Abrir Conta Kids gratuita'
                className='btn text-white btn-orange--extra text-none mt-3 mt-md-4'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    section_name: 'Cartão de débito para maior segurança',
                    element_action: 'click button',
                    element_name: 'Abrir Conta Kids gratuita',
                    redirect_url: oneLink,
                  })
                }}
              >
                Abrir Conta Kids gratuita
              </a>
            )}
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default CartaoDeDebito

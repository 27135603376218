import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina', sans-serif;
  }

  button, a {
    height: 48px;

    @media ${device.tablet} {
      max-width: 577px;
    }
  }
`

export const Card = styled.div`
  @media ${device.tablet} {
    height: 160px;
  }

  @media ${device.desktopLG} {
    height: 140px;
  }
`
